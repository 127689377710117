<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form action="#" class="searchbox" :inline="true">
                            <el-form-item>
                                <el-input v-model="Keyword" type="text" size="small" placeholder="退单编号"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tabelData" height="calc(100% - 80px)" border style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="refundCode" label="退单编号" width="240" fixed></el-table-column>
                    <el-table-column label="商品名称" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.commodity.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="退款金额（元）" align="center">
                        <template slot-scope="scope">
                            ￥{{ scope.row.refundMoney }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName" label="下单用户" align="center"></el-table-column>
                    <el-table-column prop="remark" label="退款理由" width="200" align="center"></el-table-column>
                    <el-table-column prop="payType" label="支付方式" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.payType == '0'" type="warning" size="small">余额</el-tag>
                            <el-tag v-if="scope.row.payType == '1'" type="success" size="small">微信支付</el-tag>
                            <el-tag v-if="scope.row.payType == '2'" type="primary" size="small">支付宝</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundTime" label="申请时间" width="200px" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.refundTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="220" align="center">
                        <template slot-scope="scope">
                            <span class="success optionBtn" @click="AgreeBtn(scope.row)">同意</span>
                            <span class="danger optionBtn" @click="RefuseBtn(scope.row)">拒绝</span>
                            <!-- <el-button type="primary" size="mini" @click="openDetail(scope.row)">详情</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="拒绝退单" :visible.sync="dialogRefuse" width="550px">
            <el-form :model="refuseForm">
                <el-form-item label="订单编号" :label-width="formLabelWidth">
                    {{refuseForm.orderCommodityDetail.detailCode || ''}}
                </el-form-item>
                <el-form-item label="申请时间" :label-width="formLabelWidth">
                    {{ refuseForm.refundTime | timefilters }}
                </el-form-item>
                <el-form-item label="商品名称" :label-width="formLabelWidth">
                    {{ refuseForm.commodity.name }}
                </el-form-item>
                <el-form-item label="商品规格" :label-width="formLabelWidth">
                    {{ refuseForm.commoditySpecification.name }}
                </el-form-item>
                <el-form-item label="拒绝原因" :label-width="formLabelWidth" prop="processingContent">
                    <el-input type="textarea" v-model="refuseForm.processingContent" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogRefuse = false">取 消</el-button>
                <el-button type="primary" @click="savaRefuse()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import bread from "../../../../components/common/breadcrumb";
import { Refund } from "../../../../components/HospitalDomain/ShopMall/Refund";

export default {
    name: "OwnRefund",
    components: {
        bread
    },
    data() {
        var refund = new Refund(this.TokenClient, this.Services.Shopping);
        return {
            refundDomain: refund,
            Keyword: '',
            tabelData: [],
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            DataTotal: 1,
            dialogRefuse: false,
            refuseForm: {
                orderCommodityDetail: {
                    detailCode: ""
                },
                commodity: {
                    name: ""
                },
                commoditySpecification: {
                    name: ""
                }
            },
            formLabelWidth: '100px',
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            _this.refundDomain.AuditRefund(_this.PageIndex, _this.Keyword,
                function (data) {
                    _this.tabelData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (err) {
                    console.log(err);
                });
        },
        AgreeBtn(item) {
            var _this = this;
            _this.$confirm('是否确定要同意本次退单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                _this.refundDomain.AgreeRefund(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '退单成功!'
                        });
                        _this.getList()
                    },
                    function (err) {
                        console.log(err);
                    });
            }).catch(() => {

            });

        },
        RefuseBtn(item) {
            var _this = this;
            _this.dialogRefuse = true
            _this.refuseForm = item
        },
        // 拒绝退单
        savaRefuse() {
            var _this = this;
            _this.refundDomain.RefuseRefund(_this.refuseForm,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '拒绝退单成功!'
                    });
                    _this.dialogRefuse = false
                    _this.getList()
                },
                function (err) {
                    console.log(err);
                });
        }
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/


.table-top {
    margin-bottom: -10px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    position: relative;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 300px;
    padding: 5px 40px 5px 15px;
    margin-right: 10px;
    border-radius: 5px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}
</style>
